footer{
    background-color: $footer-bg;
    min-height: 450px;
    padding-bottom: 130px;
    padding-top: 118px;
    z-index: 1;

    &::before{
        background: linear-gradient(180deg, #F7F7F7 0%, rgba(247,247,247,0) 100%);
        content: '';
        height: 42px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 3;
    }

    &::after{
        background: linear-gradient(180deg, #EAE6E6 0%, rgba(247,247,247,0) 100%);
        content: '';
        height: 8px;
        position: absolute;
        top: 0;
        width: 100%;
        opacity: .5;
        z-index: 5;
    }
    
    @media (max-width: 1200px){
        .small-column{
            margin-top: -80px;
            max-width: 95%;
            width: 95%;
        }
    }

    .link-column{
        padding-left: 45px;
        padding-right: 45px;

        &::before{
            background-color: $gray-light;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 1px;
        }
    }

    .link{
        &::before{
            background-color: $primary-blue;
            content: '';
            height: 1px;
            left: -22px;
            position: absolute;
            top: 16px;
            width: 13px;
        }
    }

    .opening-hrs{
        margin-right: 98px;
    }

    .large-column{
        .address{
            margin-right: 70px;
        }
        
        .contact{
            padding-right: 53px !important;
        }

        .column-content{
            ul{
                li{
                    &::before{
                        background-color: $primary-blue;
                        content: '';
                        height: 1px;
                        left: -13px;
                        position: absolute;
                        top: 15px;
                        width: 13px;
                    }
                }
            }
        }
    }

    .ssn{
        color: #424242;
        font-weight: 300;
        margin-top: 32px;
    }
}

@include media-breakpoint-down(xl){
    footer{
        .link-column{
            &:nth-child(3){
                padding-left: 20px;

                &::before{
                    content: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    footer{
        margin-top: 0;
        padding-top: 40px;

        .footer-container{
            padding: 41px 20px;

            >.row{
                margin-bottom: 20px !important;

                .logo{
                    img{
                        width: 96px;
                    }
                }
            }

            .link-column{
                margin-left: 0;
                padding-left: 20px;
                width: 100%;

                &::before{
                    content: none;
                }
            }

            .link{
                margin-top: 4px;
                padding-bottom: 14px;
                padding-left: 30px;
                
                &::before{
                    left: 9px;
                }

                a{
                    font-size: 18px;
                }
            }

            .opening-hrs{
                font-size: 18px;
                margin-right: 0;
            }
            
            .address, .contact{
                font-size: 18px;
                margin-bottom: 35px;
                margin-right: 0;

                .column-content{
                    font-size: 18px;
                }
            }
            
            .link-column{
                border-top: 1px solid $gray-light;
                padding: 18px 20px;

                ul{
                    li{
                        margin-bottom: 5px;

                        &::before{
                            left: -19px;
                        }

                        a{
                            font-size: 18px;
                        }
                    }
                }

            }
            
            .small-column{
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

@include media-breakpoint-down(md){
    footer{
        .small-column{
            margin-top: 40px;
        }
    }
}