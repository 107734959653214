.mapboxgl-map {
    border-radius: 16px;
    font-family: 'futura-pt' !important;
    -webkit-transform: translateZ(0);
    z-index: 1;
}

.mapboxgl-popup-tip {
    display: none !important;
}


.popup-grenndarstod {
    border: 2px solid $primary-blue;
    border-radius: 12px;

}

.popup-endurvinnslustod {
    border: 2px solid $primary-green;
    border-radius: 12px;

}


.mapboxgl-popup{
    top: -38px !important;

    &.popup-endurvinnslustod{
        .mapboxgl-popup-content{
            &::after{
                background-color: $primary-green !important;
            }
        }
    }

    .mapboxgl-popup-content {
        border-radius: 12px !important;
        padding: 15px;
        width: 260px;

        &::after{
            content: '';
            cursor: pointer;
            display: inline-block;
            padding: 6px;
            position: absolute;
            right: 47.6%;
            bottom: -14px;
            transform: rotate(45deg);
            width: 0px;
            background-color: $primary-blue;
            bottom: -6px;
            width: 20px;
            z-index: -1;
        }

        .station-popup{
            .opening-hrs{
                background-color: $text-bg;
            }

            .station-popup-close {
                background-color: $black;
                border-radius: 15px;
                cursor: pointer;
                display: block;
                height: 30px;
                margin: -24px -24px 0 0;
                width: 30px;
                &::before{
                    content: '';
                    background: $white;
                    height: 17px;
                    transform: translate(5px, 6px) rotate(45deg);
                    position: absolute;
                    width: 1.5px;
                    
                }
                &::after{
                    content: '';
                    background: $white;
                    height: 17px;
                    position: absolute;
                    transform: translate(-6px, 6px) rotate(-45deg);
                    width: 1.5px;
                }
            }
        }

        img{
            top: 14px;
            width: 46px;
        }

        .title{
            line-height: 8px;
            font-size: 22px;
            min-height: 60px;
            padding-left: 56px;
            span{
                font-size: 14px;
                font-weight: 400;
            }
            &.nosub{
                line-height: 22px;
            }
        }

        .opening-hours {
            background-color: #F6E9D0;
            font-size: 16px;
            line-height: 40px;
            margin-bottom: 13px;
            min-height: 39px;
            &::before{
                content: '';
                background-color: $primary-green;
                border-radius: 50%;
                height: 10px;
                left: 30px;
                position: absolute;
                top: 15px;
                width: 10px;
            }
        }

        .station-detail-button-container {
            margin: 5px 0 15px 0;
            &.twocol{
                width: 150px;
                margin-left: auto;
                margin-right: auto;
            }

            .station-detail-button {
                border-radius: 4px;
                font-size: 14px;
                padding: 8px 15px;

                &--grenndarstod {
                    border: 1px solid $primary-blue;
                }

                &--endurvinnslustod {
                    border: 1px solid $primary-green;
                }
                &--starfsstod {
                    border: 1px solid $primary-blue;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .mapboxgl-map {
        min-height: 450px;
    }
}