.page-categorypage,
.page-categoryindexpage,
.page-stationindexpage {
    header {
        margin: 0 !important;
    }
}

.page-stationindexpage {
    header {
        .top-menu-row {
            @include media-breakpoint-up(lg) {
                width: 50vw;
            }
        }
    }
}

body.split-screen {
    .top-menu-row {
        .search-container {
            margin-right: 20px !important;

            &:not(.is-focused) {
                @include media-breakpoint-up(md) {
                    margin-right: 35px !important;
                    padding-right: 0px !important;
                }

            }

            &.is-focused {
                width: 45vw;
            }
        }

        .close-search {
            right: 20px !important;
        }
    }
}


header {
    height: 104px;
    margin-bottom: 8px;

    .google-translate-container {
        position: absolute;
        right: 7%;
        max-width: fit-content;

        @media (max-width: 1200px) {
            right: 9%;
        }

        @media (max-width: 992px) {
            right: 12%;
        }


        .ct-topbar__list {
            margin-bottom: 0px;
        }

        .ct-language{
            position: relative;

            &:hover{
                box-shadow: 0 20px 40px 0 rgba(0,0,0,0.09);
                cursor: pointer;

                span{
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;

                    &::after{
                        transform: rotate(-134deg);
                        top: 18px;
                    }
                }

                .ct-language__dropdown{
                    max-height: 200px !important;
                    transition: max-height .3s linear;
                }
            }

            span{
                border: 1px solid #D2D2D2;
                font-size: 18px;
                font-weight: 500;
                color: #313E3E;
                border-radius: 2px;
                padding: 3px 36px 3px 13px;
                display: block;
                position: relative;

                &::after {
                    content: '';
                    cursor: pointer;
                    right: 10px;
                    top: 13px;
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border: solid $font-light;
                    border-width: 0 1.5px 1.5px 0;
                    display: inline-block;
                    padding: 4px;
                    transform: rotate(45deg)
                }
            }

            .ct-language__dropdown {
                background: white;
                box-shadow: 0 20px 40px 0 rgba(0,0,0,0.09);
                left: 1px;
                transition: max-height .3s linear;
                overflow: hidden;
                position: absolute;
                top: 100%;
                width: calc(100% - 2px);
                text-align: center;
                max-height: 0;
                z-index: 200;

                li {
                    padding: 5px;
    
                    &:first-child {
                        padding-top: 10px;
                    }
    
                    &:last-child {
                        padding-bottom: 10px;
                    }        
    
                    a {
                        display: block;
                    }
                }
            }
        }
        .list-unstyled {
            padding-left: 0;
            list-style: none;
        }
    }

    &.show-menu {
        body:not(.split-screen):not(.page-stationindexpage) & {
            @include media-breakpoint-up(lg) {
                flex-wrap: wrap;

                .toggler-container {
                    width: 12.5% !important;
                }

                .logo-container {
                    width: 37.5% !important;
                }
            }
        }

        nav {
            .top-menu-row {
                transition: width .3s linear;
            }
        }

        .menu-wrapper {
            z-index: 10;

            .pages-container {
                height: calc(100vh - 108px);
                transform: translateX(16px);
                transition: transform .2s linear;
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .menu-image {
                transition: opacity .2s linear;
                opacity: 1;

                img {
                    height: 100%;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }

        .search-container.is-focused {
            body:not(.split-screen) & {
                width: 44vw !important;
            }
        }
    }

    &:not(.show-menu) {
        body:not(.split-screen):not(.page-stationindexpage) & {
            background-color: $white;
            position: fixed;
            top: 0;
            z-index: 10;

            &.up {
                transform: translateY(-100%);
                transition: transform .2s linear;
            }

            &.down {
                transform: translateY(0);
                transition: transform .2s linear;
            }
        }

        .menu-wrapper {
            padding: 0 !important;

            .menu-image {
                height: 0;
            }
        }
    }

    .top-menu-row {
        transition: width .3s linear;

        .overwrite-width {
            .search-container.is-focused {
                body:not(.split-screen) & {
                    width: 44vw;
                }
            }
        }

        .search-container {
            background-color: $white;
            height: 28px;
            transition: width .3s linear;
            transform: translateY(-50%);
            width: 24px;

            &:not(.is-focused) {
                padding-right: 74px;
            }

            .search-icon {
                background-color: $white;
                left: 0;
                position: absolute;
                top: 3px;
                z-index: 1;
            }

            &.is-focused {
                height: auto;
                position: relative;
                transition: width .3s linear;
                margin-right: 50px;
                top: 0 !important;
                overflow: visible !important;
                width: calc(100% - 50px);

                &::after {
                    background-color: $primary-blue;
                    content: '';
                    height: 34px;
                    position: absolute;
                    right: 51px;
                    top: 10px;
                    width: 1px;
                }

                .search-icon {
                    left: 18px;
                    top: 15px;
                }

                input {
                    background-color: transparent;
                    border-radius: 3px;
                    height: 54px;
                    padding: 0 70px;

                    &:focus,
                    &:active {
                        outline: none;
                    }

                    &::placeholder {
                        color: $black;
                        font-size: 1.8rem;
                        font-style: italic;
                        line-height: 2.3rem;
                    }
                }
            }

            label {
                font-family: 'futura-pt';
                line-height: 54px;
            }
        }
    }

    .close-search {
        right: 50px;
        width: 52px;

        .close {
            width: 18px;
        }
    }

    .menu-wrapper {
        background-color: $white;

        .menu-image {
            height: 100vh;
            transition: opacity .2s linear;
            opacity: 0;
            width: 50vw;
            z-index: -1;

            img {
                object-fit: cover;
            }
        }

        .pages-container {
            background-color: $white;
            padding-top: 108px;
            transform: translateX(-100%);
            transition: transform .2s linear;
            top: 108px;
            width: 50vw;
            z-index: -2;

            ul {
                &:not(.submenu) {
                    padding-bottom: 200px !important;

                    li:not(.submenu-item) {
                        &:last-child {
                            border-bottom: 1px solid #e1e1e1 !important;
                        }
                    }
                }

                li {
                    font-size: 28px;
                    min-height: 77px;
                    border-bottom-color: rgba(20, 20, 20, .15);

                    &:not(.submenu-item) {
                        &::after {
                            content: '';
                            right: 20px;
                            top: 38px;
                            position: absolute;
                            display: block;
                            width: 0px;
                            height: 0px;
                            border: solid $font-light;
                            border-width: 0 1.5px 1.5px 0;
                            display: inline-block;
                            padding: 5px;
                            transform: rotate(45deg);
                            transition: transform .2s linear;
                            pointer-events: none;
                        }
                    }

                    &.active {
                        background-color: transparent;
                        border-color: rgba(20, 20, 20, 0.15);
                    }

                    &.flokkun {
                        &::after {
                            transform: rotate(-45deg);
                        }

                        ul {
                            display: none !important;
                        }
                    }

                    &.show-ul {
                        &:not(.submenu-item) {
                            &::after {
                                border-color: $primary-blue;
                                transform: rotate(224deg);
                                transition: transform .2s linear;
                            }
                        }
                    }

                    a {
                        padding: 23px 0;

                        &:hover {
                            color: $black;
                        }

                        &::after {
                            background-color: $primary-blue;
                            bottom: 0;
                            content: '';
                            height: 1px;
                            left: 0;
                            position: absolute;
                            transition: width .3s linear;
                            width: 0;
                        }

                        &.active {
                            &::before {
                                background-color: $primary-blue;
                                content: '';
                                height: 2px;
                                left: -34px;
                                position: absolute;
                                top: 43px;
                                width: 22px;
                            }

                            &::after {
                                transition: width .3s linear;
                                width: 100%;
                            }
                        }
                    }

                    .submenu {
                        max-height: 0;
                        transition: max-height .6s linear;
                        overflow: hidden;

                        &.open-list {
                            max-height: 700px;
                            transition: max-height .6s linear;
                        }

                        .submenu-item {
                            min-height: 46px;
                            padding-left: 35px;
                            transition: padding-left .2s linear;

                            &.active {
                                a {
                                    color: $primary-blue;
                                }
                            }

                            &:first-child {
                                padding-top: 20px !important;

                                &::before {
                                    top: 43px !important;
                                }
                            }

                            &:last-child {
                                padding-bottom: 20px !important;
                            }

                            &::before {
                                background-color: $primary-blue;
                                content: '';
                                height: 2px;
                                left: 0;
                                position: absolute;
                                transition: width .2s linear;
                                top: 23px;
                                width: 22px;
                            }

                            &:hover,
                            &.active {
                                padding-left: 43px;
                                transition: padding-left .2s linear;

                                &::before {
                                    transition: width .2s linear;
                                    width: 30px;
                                }
                            }

                            a {
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    nav {
        margin-top: 38px;
        min-width: 100%;
        width: 100vw;
        z-index: 100;
    }

    .lang {
        font-size: 1.8rem;
        margin-right: 22px;

        &:last-child {
            margin-right: 0;
        }
    }

    .navbar-toggler {
        padding-left: 0;

        span {
            background-color: $black;
            border-radius: 8px;
            height: 2px;
            margin-bottom: 6px;
            transform: rotate(0) translate(0, 0);
            transition: transform .5s ease-in-out, width .5s ease-in-out;
            width: 38px;
        }

        &.open {
            span {
                &:first-child {
                    transform: rotate(45deg) translate(6px, 6px);
                    transition: transform .5s ease-in-out;
                }

                &.middle {
                    transition: width .5s ease-in-out;
                    width: 0;
                }

                &:last-child {
                    transform: rotate(-45deg) translate(6px, -4px);
                    transition: transform .5s ease-in-out;
                }
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .page-stationindexpage {
        header {
            .top-menu-row {
                width: 100%
            }
        }
    }

    header {
        &.show-menu {
            .google-translate-container{
                width: 100%;
                max-width: unset;
                right: unset;
                left: 0;
            }

            nav {
                .top-menu-row {
                    width: 100% !important;
                }
            }

            .menu-wrapper {
                .pages-container {
                    transform: translateX(7px);
                    padding-bottom: 200px;

                    ul:not(.submenu){
                        padding-bottom: 20px !important;
                    }

                    .ct-topbar{
                        span{
                            color: #808080;
                            display: block;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 23px;
                            margin-bottom: 18px;
                        }

                        ul.lang-list{
                            padding-bottom: 0 !important;

                            li{
                                border-bottom: none !important;
                                margin-right: 32px;

                                &::after{
                                    content: none;
                                }

                                a{
                                    color: #313E3E;
                                    display: block;
                                    font-size: 20px;
                                    font-weight: 500;
                                    line-height: 26px;
                                    text-transform: uppercase;
                                    padding: 0 !important;
                                }
                            }
                        }
                    }

                }

                .menu-image {
                    width: 100vw;
                }
            }
        }

        .top-menu-row {
            transition: width .3s linear;
            width: 100% !important;

            .toggler-container {
                span {
                    width: 30px;
                }
            }

            .search-container {
                right: 0px;
                padding-right: 0 !important;
                top: 0 !important;

                &.is-focused {
                    top: 100px !important;
                    width: 86vw !important;
                    margin-right: 0;

                    &::after {
                        content: none;
                    }

                    .search-results-container {
                        background-color: $white;
                        height: 100vh;
                        left: -53px;
                        padding: 25px;
                        position: fixed;
                        width: 100vw;
                    }
                }
            }
        }

        .menu-wrapper {
            .pages-container {
                width: 100vw;
                z-index: 2;
            }
        }

        .outer-search-container {
            .close-search {
                right: 0 !important;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    header {
        &.show-menu {
            nav {
                .top-menu-row {
                    width: 100% !important;
                }
            }

            .pages-container {
                height: 100vh !important;
                transform: translateX(3px) !important;
            }

            .menu-wrapper {
                .menu-image {
                    width: 100vw;
                }
            }
        }

        .top-menu-row {
            transition: width .3s linear;
            width: 100% !important;

            .toggler-container {
                span {
                    width: 30px;
                }
            }

            .search-container {
                right: 0px;
                padding-right: 0 !important;
                top: 0 !important;

                &.is-focused {
                    .search-results-container {
                        left: -25px;
                    }
                }
            }
        }

        .menu-wrapper {
            .pages-container {
                top: 66px;
                width: 100vw;
            }
        }
    }
}

