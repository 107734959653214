$white: #fff;
$black: #000;

$font: #121313;
$font-light: #141414;
$font-lighter: #525252;

$btn-disabled: #dbd9d9;

$light-grey-bg: #f7f7f7;
$table-bg: #f8f8f8;
$footer-bg: #fcfcfc;
$table-border: #D4D4D4;
$text-bg: #F6E9D0;
$info-bg: #E5F5EE;
$warning-bg: #FAF7D3;

$light-blue-bg: #d0e8f280;
$light-green-bg: #00a2551a;
$primary-blue: #009EC9;
$primary-green: #00a255;
$border-color: #e1e1e1;
$border-color-2: #E6E6E6;

$gray-d1: #4E4E4E;
$gray: #979797;
$gray-light: #e0e0e0;
$gray-l1: #DCDCDC;
$gray-l2: #888888;
$gray-l3: #F1EFEF;

$gardaurgangur: #006432;
$matarleifar: #00A04B;
$nytjahlutir: #87C84B;
$glerogpostulin: #1DAF8E;
$pappir: #0082BE;
$byggingaurgangur: #004B82;
$pappi: #BEA064;
$rafografeindataeki: #F0910A;
$ahaettuurgangur: #E10F1E;
$plast: #961E82;
$malmar:   #5A6E78;
$blandadururgangur: #141414;

$blue-hover: #0D82A2;
