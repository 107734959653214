.blockquote-block{
    blockquote{
        background-color: rgba(0, 158, 201, .08);
        border-radius: 16px;
        padding: 42px 60px 30px 30px;
        p{
            font-size: 2.2rem;
        }
    }
}

@include media-breakpoint-down(md){
    .blockquote-block{
        blockquote{
            p{
                font-size: 2rem;
                line-height: 2.6rem;
                margin: 14px 0;
            }
        }
    }   
}