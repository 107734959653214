.search-results-container{
    ul{
        background-color: $white;
        box-shadow: 0 20px 40px 0 rgba(0,0,0,0.09);
        margin-top: 8px;
        max-height: 815px;
        padding: 27px 66px;
        overflow-x: hidden;
        overflow-y: scroll;

        /* width */
        &::-webkit-scrollbar {
            width: 7px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #EEEEEE;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #B5CED5;
        }

        li{
            &:hover{
                h3{
                    color: $primary-blue;
                    text-decoration: none;
                }

                a{
                    margin-top: -1px;
                }
            }

            h3{
                padding-bottom: 3px;
            }
            
            .breadcrumb{
                color: $gray;
                font-size: 1.2rem;
                letter-spacing: .3px;
            }

            .excerpt{
                height: 92px;
                overflow: hidden;
            }
        }
    }
    
}

@include media-breakpoint-down(lg){
    .search-results-container{
        ul{
            box-shadow: none;
            padding: 20px 20px 300px 20px;
        }
    }
}
