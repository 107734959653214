.question-list{

    .title{
        line-height: 55px;
    }

    .inner-title{
        margin-bottom: 20px;

        &:first-child{
            margin-top: 50px;
        }

        &:not(:first-child){
            margin-top: 40px;
        }
    }

    ul{
    //    display: none;
        
        li{
            font-size: 2rem;
            transition: max-height .3s linear;

            // &:not(.open){
                &:hover{
                    .question{
                        p{
                            color: $primary-blue;
                        }
                    }

                    &.open{
                        .question{
                            p{
                                color: $black;
                            }
                        }
                    }
                }
            // }
            
            &.open{
                max-height: 3000px;
                transition: max-height .3s linear;
                
                .question{
                    p{
                        // color: $primary-blue;
                        font-weight: 600;
                    }
                    
                    .plus-icon{
                        transition: transform .2s linear;
                        transform: rotate(-45deg) translate(0, 0);
                        
                        span{
                            background-color: $primary-blue;
                        }
                    }
                }
            }
            
            .question{
                min-height: 63px;
                padding-right: 50px;
                
                .plus-icon{
                    height: 16px;
                    right: 18px;
                    transition: transform .2s linear;
                    transform: rotate(0deg) translate(4px, 4px);
                    top: 23.5px;
                    width: 16px;
                    
                    span{
                        background-color: $black;
                        height: 16px;
                        width: 1.5px;
                        
                        &:first-child{
                            transition: transform .2s linear;
                            transform: rotate(90deg) translate(0, 0);
                        }
                        
                        &:last-child{
                            transition: transform .2s linear;
                            transform: rotate(0deg) translate(0, -16px);
                        }
                    }
                }
            }
            
            .answer{
                display: none;
                
                div[class*='col-']{
                    max-width: 100% !important;
                    min-width: 100% !important;
                }
            }
        }
    }
}