.page-stationpage{
    .bordered{
        h3{
            border-bottom: 1px solid $gray !important;
            display: block;
            padding-bottom: 8px;
            margin-bottom: 19px;
        }

        .image-container{
            min-width: 95px;

            .station-icon{
                max-width: 95px;
                width: 100%;
            }
        }

        .opening-hrs-container{
            background-color: $text-bg;
            padding: 17px 34px;
            max-width: 300px;

            .opening-hrs{
                margin-bottom: 15px;
                
                span.dot{
                    left: -18px;
                    top: 10px;
                    position: absolute;
                }
            }
        }

        .station-page-ul{
            padding-top: 0;
        }

        .categories-list{
            display: flex;
        }

        p, li{
            color: $font-lighter;
        }
        
        a{
            padding-top: 4px;
        }

        .category-page-ul{
            ul{
                li.cat-parent{
                    &::after{
                        transition: transform .2s linear;
                    }

                    &.open{
                        &::after{
                            border-color: $primary-blue;
                            transform: rotate(45deg);
                            transition: transform .2s linear;
                        }
                    }
                }

                .cat-container{
                    height: fit-content;
                    width: 149px;

                    &::after{
                        content: none;
                    }

                    span{
                        border: 1.14px solid #000;
                        line-height: 16px;
                        width: 100%;
                    }

                    img{
                        width: 37px;
                    }
                }
                ul{
                    display: none;
                    padding: 2px 0;

                    li{
                        height: fit-content;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .page-stationpage{
        .left-column{
            display: none;
        }

        .bordered{
            border: 0;
        }
    }
}

@include media-breakpoint-down(sm){
    .page-stationpage{
        .bordered{
            .image-container{
                min-width: 75px;
    
                .station-icon{
                    max-width: 75px;
                }
            }
        }
    }
}