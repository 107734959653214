.form-page {
    margin-top: 114px;

    .skilmalar{
        ol{
            li{
                margin-top: 30px;
            }
        }
    }

    p {
        margin-bottom: 18px;
        position: relative;

    }

    form#vidskiptakort{
        label.checkbox{
            transform: unset !important;
            margin-left: 30px;
        }

        input.checkbox{
            width: 20px;
        }

        span.error{
            color: $red;
            font-size: 14px;
            
        }

        .g-recaptcha{
            height: 100px;
            width: 34%;

            >div{
                width: 100% !important;

                iframe{
                    min-width: 100%;
                }
            }
        }
    }

    label {
        color: #232323;
        font-size: 1.8rem;
        position: absolute;
        transform: translateX(18px);
        top: 18px;

        &:not(.checkbox){
            &.animate-label {
                color: $gray;
                font-size: 12px;
                line-height: 18px;
                transform: translateX(18px);
                transition: transform 0.2s linear, top .2s linear, font-size .2s linear;
                top: 2px;
            }
        }
    }

    input {
        height: 64px;
    }

    svg.arrow {
        right: 24px;
        transform: rotate(180deg);
        top: 28px;
    }

    .contact-type {
        padding: 24px 20px 12px 20px;

        .radio-btn {
            width: auto;

            label {
                top: -5px;
                transform: none;
            }

            input {
                height: 16px;
                margin-right: 5px;
                width: 16px;
            }
        }
    }

    .file-upload-container {
        min-height: 64px;

        .file {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    input,
    textarea,
    select {
        border: 1px solid $border-color;
        border-radius: 3px;
        font-size: 1.8rem;
        padding: 24px 20px 12px 20px;
        width: 100%;

        &:active,
        &:focus {
            border: 1px solid $primary-blue;
            box-shadow: none;
            outline: none;
        }
    }

    button,
    .btn {
        background-color: transparent;
        border: 1px solid $primary-blue;
        border-radius: 23px;
        font-size: 1.6rem;
        font-weight: 600;
        height: auto;
        max-width: 150px;
        padding: 8px 16px;
        transition: background-color .3s linear;

        &:hover {
            background-color: $primary-blue;
            color: $white;
            transition: background-color .3s linear;
        }
    }
}