.page-newspage{
    section{
        padding-top: 114px;
    }

    .news-page{
        @include title-img;

        .header-container{
            margin-bottom: 60px;

            @include media-breakpoint-down(md){
                padding: 0 !important;
            }

            .timestamp{
                color: $primary-blue;
            }
        }
    }
}