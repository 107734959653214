.news-index-page{
    .news-headline-row{
        padding-top: 114px;

        h1{
            border-bottom-color: #dfdfdf !important;
            margin-bottom: 22px;
            padding-bottom: 8px;
        }
    }

    .news{
        img{
            min-height: 270px;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    button{
        margin-top: 69px;

        &:hover{
            border-color: $primary-blue !important;
            color: $primary-blue;
        }
    }
}

@include media-breakpoint-down(md){
    .news-index-page{
        .news-block{
            margin-bottom: 60px;
        }
    }   
}