.category-search {
    &.is-focused {
        .close {
            transform: translateY(-50%) rotate(0deg) !important;
            z-index: 2 !important;
        }
    }

    .close-icon-container {
        height: 110px;
        width: 80px;

        .close {
            height: 20px;
            transform: translateY(-50%) rotate(45deg);
            transition: transform .2s linear;
            // z-index: -1;
        }
    }

    img {
        height: auto;
    }

    input {
        background-color: transparent;
        font-size: 2.4rem;
        height: 90px;
        z-index: 1;

        body.page-homepage & {

            &:focus,
            &:active {
                border-bottom: none !important;
            }
        }

        &:focus,
        &:active {
            outline: none;
        }

        &::placeholder,
        &::-moz-placeholder {
            color: $black;
            font-size: 2.4rem;
            opacity: 1;
        }
    }

    &.secondary {
        .search-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 13px;
            width: 19px;
            height: 19px;
        }

        input {
            height: 46px;
            padding: 10px !important;
            font-size: 2rem;
            font-weight: 500;
            border-radius: 4px !important;
            padding-left: 52px !important;

            &.flat-bottom{
                border-bottom-left-radius:  0px !important;
                border-bottom-right-radius: 0px !important;
            }
            
            &::placeholder,
            &::-moz-placeholder {
                font-size: 2rem;
                font-weight: 500;
            }
            
            
            &+.close-icon-container {
                display: none !important;
                height: 46px;
                width: 46px;
                padding: 13px;

                svg {
                    position: absolute;
                    top: 50%;
                }
            }
            
            :not(:placeholder-shown)+.close-icon-container {
                display: flex !important;
            }

        }
        
        .categories{
            margin-top: -1px; 
        }

        &.is-focused {
            input {
                border: 1px solid $primary-blue !important;
            }
        }
    }

    a.back {
        left: 34px;
        z-index: 3;
        top: 114px;

        &:hover {
            color: $black;

            svg {
                transform: rotate(180deg) translateX(4px);
                transition: transform .2s ease-in-out;
            }
        }

        .arrow-back {
            height: 15px;
            margin-right: 6px;
            transform: rotate(180deg) translateX(0);
            transition: transform .2s ease-in-out;
        }
    }

    ul.categories {
        background-color: $white;
        box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
        max-height: 0;
        transition: max-height .5s ease-in-out;
        overflow: hidden;
        z-index: 2;
        @include categories-li;

        &.list-open {
            border: 1px solid $primary-blue;
            max-height: 750px;
            min-height: 750px;
            padding: 53px 32px !important;
            transition: max-height .5s ease-in-out;
        }

        &.search-results {
            min-height: unset !important;
            padding-top: 18px !important;

            li {
                height: 43px;

                &::after {
                    content: none;
                }

                a {
                    &:hover {
                        color: $primary-blue;
                        text-decoration: underline;
                    }
                }
            }
        }

        ul.inner-ul {
            @include category-list;
            top: calc(100% + 26px);

            li {
                height: auto !important;

                &::after {
                    content: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .category-search {
        input {
            font-size: 2.2rem;
        }
    }
}

@include media-breakpoint-down(md) {
    .category-search {
        .close-icon-container {
            height: 80px;
            width: 50px;
        }

        input {
            font-size: 1.8rem;
            height: 60px;
            padding: 15px 30px 14px 10px !important;

            &:focus,
            &:active {
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }

        svg.close {
            right: 18px;
            width: 14px;
        }

        a.back {
            left: 14px;
            top: 80px;
        }

        ul.categories {
            &.list-open {
                padding: 25px 15px 53px 15px !important;
            }

            &.search-results {
                padding-left: 15px !important;
            }
        }
    }
}