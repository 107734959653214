.page-standardpage{
    section{
        padding-top: 100px;
        min-height: 76vh;

        .pricelist{
            &--item{
                .pricelist--item--description{
                    text-overflow: unset !important;
                    white-space: pre-wrap;
                }

                &.header{
                    margin-top: 50px;       
                }
            }

        }
    }
}

@include media-breakpoint-down(md){
    .page-standardpage{
        section{
            .pricelist-table-container{
                margin-left: -40px;
                padding-left: 40px;
                overflow: scroll;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                .pricelist{
                    &--item{

                        // &--description{
                        //     // max-width: 40%;
                        // }

                        &--price{
                            width: 100px;
                        }

                        &--code{
                            display: none;
                        }
        
                        &.header{
                            margin-top: 50px;             
                        }
                    }
                }
            }
        }
    }
}