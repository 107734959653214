.category-page-ul, .station-page-ul{
    padding-top: 50px;
    
    .back{
        padding-left: 20px;
        
        &::before{
            content: '';
            left: 2px;
            top: 36%;
            position: absolute;
            display: block;
            width: 0px;
            height: 0px;
            border: solid $font-light;
            border-width: 0 1.5px 1.5px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(-226deg);
            transition: left .2s ease-in-out;
        }
        
        &:hover{
            color: $black;

            &::before{
                left: -2px;
                transition: left .2s ease-in-out;
            }

            a{
                color: $black;
            }
        }
    }

    ul{
        li.current-page{
            cursor: auto !important;

            &::after{
                content: none !important;
            }
        }
        li.open {
            height: unset !important;
        }
    }

    .categories-list{
        @include category-list;


        .cat-container{
            max-width: 105px;

            .active{
                &::after{
                    background-color: $font-light;
                    border-radius: 50%;
                    bottom: -20px;
                    content: '';
                    height: 12px;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    width: 12px;
                }
                
                img, span{
                    opacity: 50%;
                }
            }
        }
    }
}