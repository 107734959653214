.anchor-block{
    ul{
        li{
            list-style: none;

            &:first-child{
                color: #808080;
            }

            a{
                color: $blandadururgangur;
                font-size: 18px;
                
            }
        }
    }
}