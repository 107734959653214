.page-homepage{
    .map-row{
        height: 800px;

        .message{
            padding-right: 100px;
        }

        #map{
            max-height: 800px;
        }

        .categories-search-container{
            margin-bottom: 40px;

        }
        
        .category-search{
            input::placeholder{
                color: $font;
            }    
        }

        .next-emptying, .closest-station{
            background-color: $light-blue-bg;
            padding: 24px 35px 20px 20px;
            margin-bottom: 16px;
            min-height: 145px;

            .svg-container{
                svg{
                    height: 44px;
                    min-width: 28px;
                    width: 50px;
                }
            }

            .opening-hrs, .type{
                @media(min-width: 992px) and (max-width: 1400px){
                    font-size: calc(13px + (18 - 13) * ((100vw - 992px) / (1400 - 992))) !important;
                }
            }

            .see-all{
                a{
                    border-bottom: 1px solid $primary-blue;
                    color: $gray-d1;
                    padding-bottom: 4px;
                    &:hover{
                        color: $black;
                    }
                }
            }

            .input-field{
                min-width: 380px;

                input{
                    border-radius: 3px;
                    height: 44px;
                    padding-left: 18px;
                    color: $black;
                    font-size: 16px;

                    &:focus, &:active{
                        outline: none;
                        border: 2px solid $primary-blue;
                    }

                    &::placeholder{
                        color: $black;
                        font-size: 16px;
                    }
                }
            }
        }

        .green-bg{
            &.closest-station{
                background-color: $light-green-bg;
                
                .dot{
                    background-color: $primary-green;
                    height: 14px;
                    min-width: 14px;
                    width: 14px;
                }

                .see-all{
                    a{
                        border-bottom: 1px solid $primary-green;
                    }
                }
            }
        }
        .label{
            font-size: 1.2rem;
            line-height: 1.6rem;
        }
    }
}

.iframe-youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: -56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-between(sm, lg){
    .page-homepage{
        .map-row{
            .next-emptying, .closest-station{
                padding: 10px 7px 5px 12px;
                font-size: 13px;
                line-height: 16px;

                .svg-container{
                    svg{
                        height: 21px;
                        margin-right: 7px;
                        width: 14px;
                        height: 22px;
                        min-width: 14px;
                        width: 14px;
                    }
                }

                span.label{
                    font-size: 10px;
                }
           
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .page-homepage{
        .map-row{
            height: auto;
            
            .next-emptying, .closest-station{
                margin-bottom: 20px;
            }

            .closest-station{
                flex: 0 0 auto;
                padding: 24px 16px 20px 16px;
                width: 49%;

                .dot{
                    height: 7px !important;
                    min-width: 7px !important;
                    width: 7px !important;
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .page-homepage{
        .map-row{
            .message{
                margin-bottom: 20px !important;
                margin-top: 45px;
                padding-right: 0;
            }

            .next-emptying, .closest-station{
                margin-bottom: 20px;
            }

            .next-emptying{
                svg{
                    height: 35px !important;
                    width: 27px !important;
                }

                .input-field{
                    min-width: 100%;
                }
            }

            .see-all{
                a{
                    font-size: 14px;
                }
            }
        }

        .category-search{
            .categories{
                li{
                    ul.inner-ul{
                        .cat-container{
                            min-width: 100px;

                            img, span{
                                max-width: 100px;
                                width: auto !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .page-homepage{
        .map-row{
            .next-emptying, .closest-station{
                .inner-col{
                    min-width: 250px;
                }    
            }

            .closest-station{
                flex: 0 0 auto;
                width: 100%;
            }
        }
    }
}