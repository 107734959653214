.page-flokkumpage{
    .flokkum-page{
        margin-top: 100px;

        .header-container{
            .title-img{
                min-width: unset !important;
            }
            
            figure{
                max-height: unset;
                margin-bottom: 80px;
            }
        }

        .title-container{
            margin-bottom: 80px;

            h2{
                color: $primary-green;
                font-size: 5.2rem;
                line-height: 5.6rem;
                margin-bottom: 23px;
            }
        }

        .content-anchor{
            display: block;
            height: 50px;
            margin-bottom: 100px;
            position: relative;
            width: 36px;

            .arrow{
                &::after{
                    content: '';
                    cursor: pointer;
                    top: 13px;
                    position: absolute;
                    width: 0px;
                    height: 0px;
                    border: solid $primary-blue;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 12px;
                    transform: rotate(45deg);
                    left: 0;
                  }
            }
        }
    }
}