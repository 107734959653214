.paragraph-block{
    p{
        color: $font-lighter;
    }

    ul{
        margin-left: 20px;

        li{
            &::marker{
                font-size: 1.6rem;
            }

            margin-bottom: 8px;
        }
    }

    a{
        border-bottom: 1px solid $primary-blue;
        color: $font-light;
        display: inline;
        font-weight: 500;

        &:hover{
            color: $primary-blue;
        }
    }
    .responsive-object {
        position: relative;
    }
    
    .responsive-object iframe,
    .responsive-object object,
    .responsive-object embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img{
        width: 100%;
        height: auto;

        &.full-width{
            height: auto;
            margin-bottom: 118px;
            margin-top: 60px;
            max-height: 595px;
            width: 100%;
        }

        &.left{
            float: left;
            margin: 25px 30px 50px 0;
            max-width: 50%;
        }

        &.right{
            float: right;
            margin: 25px 0 50px 30px;
            max-width: 50%;
        }
    }

    &.subcategory{
        margin-bottom: 70px;

        h3{
            border-bottom: 1px solid #e1e1e1;
            display: block;
            padding-bottom: 8px;
            margin-bottom: 19px;
        }

        p{
            margin-bottom: 0;
        }
    }
}

@include media-breakpoint-down(md){
    .paragraph-block{
        img{
            &.full-width{
                height: auto;
                margin-bottom: 80px;
                margin-top: 60px;
                max-height: 595px;
                width: 100%;
            }
    
            &.left, &.right{
                float: unset;
                margin: 20px 0;
                max-width: 100%;
            }
        }
    }
}