ul.nav {
    .paragraph-block & {
        margin-left: 0;
    }
    &.nav-tabs {
        margin-bottom: 50px;
        border-bottom: 1px solid rgba($font-light, 0.15);
        li.nav-item {
            margin-bottom: 0;
            font-size: 2.8rem;
            font-weight: 500;
            min-width: 160px;
            margin-right: 40px;
            .nav-link {
                border: none;
                color: #a0a0a0;
                font-weight: 500;
                padding-left: 0;
                &.active {
                    color: #232323;
                    border-bottom: 4px solid $primary-blue;

                }
            }
        }
    }
}