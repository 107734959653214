@mixin category-list {
    .cat-container {
        img {
            width: 105px;

            @include media-breakpoint-down(sm){
                width: 90px;
            }
        }

        span {
            border-width: 1px;
            border-style: solid;
            border-top: none;
            height: 30px;
            font-size: 1.2rem;
            width: 105px;
            letter-spacing: .5px;
            line-height: 12px;
            padding: 4px 8px;
            min-height: 30px;

            @include media-breakpoint-down(sm){
                width: 90px;
            }
        }
    }
}

@mixin categories-li {
    li {
        margin-top: -1px;
    }

    &.pt-increase {
        padding-top: 63px !important;

        li {
            &:hover {
                cursor: auto;
                padding-left: 0 !important;

                .colorbar {
                    transition: none;
                    width: 36px;
                }
            }

            &::after {
                content: none;
            }
        }
    }

    &.list-open {
        border: 1px solid $primary-blue;
        max-height: 750px;
        min-height: 750px;
        padding: 53px 32px !important;
        transition: max-height .5s ease-in-out;
    }


    li {
        border-color: rgba(20, 20, 20, .14);
        cursor: pointer;
        height: 58px;
        vertical-align: middle;

        &::after {
            content: '';
            right: 20px;
            top: 27px;
            position: absolute;
            display: block;
            width: 0px;
            height: 0px;
            border: solid $font-light;
            border-width: 0 1.5px 1.5px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(-45deg);
        }

        &:hover {
            a {
                color: $black;
            }

            .colorbar {
                transition: width .2s ease-in-out;
                width: 44px;
            }
        }

        a {
            line-height: 48px;
        }

        .colorbar {
            height: 6px;
            transition: width .2s ease-in-out;
            width: 36px;
        }
    }
}

@mixin title-img {
    .title-container {
        padding-right: 20%;
        margin-bottom: 30px;
    }

    .title-img {
        img {
            max-height: 670px;
            object-fit: cover;
        }
    }
}

@mixin pricelist {
    &--header {
        margin-bottom: 20px;

        h4 {
            border: none !important;
            // font-size: 1.8rem;
        }

        a.terms {
            border-radius: 4px;
            padding: 6px 12px 7px 12px;
        }

    }

    &--body {}

    &--item {
        display: flex;
        position: relative;
        border-bottom: 1px solid rgba($font-light, 0.15);
        padding: 15px 0;

        &.header {
            .pricelist--item--price {
                font-size: 1.8rem;
                font-weight: 600;
                span {
                    font-weight: normal;
                    font-size: 1.6rem;
                }
            }

            .pricelist--item--description {
                padding-top: 10px;
                font-size: 2.4rem;
                font-weight: 500;

                a {
                    text-decoration: none;
                    border: none;
                }
            }
        }

        &--code {
            min-width: 125px;
            text-align: right;
            margin-right: 15px;
            color: #909090;
        }

        &--description {
            flex-grow: 3;
            overflow: hidden;
            white-space: nowrap;
            font-size: 2rem;
        }

        &--price {
            flex-shrink: 0;
            width: 125px;
            text-align: right;
            font-size: 2rem;
        }

        &--color {
            display: block;
            height: 6px;
            transition: width .2s ease-in-out;
            width: 36px;
            position: absolute;
            left: -50px;
            top: 50%;

            @include media-breakpoint-down(md){
                left: -45px;
                top: 46%;
            }
        }
    }
}