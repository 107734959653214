.page-stationindexpage, .page-stationpage{
    section{
        min-height: 77vh;
    }

    .mapboxgl-map{
        margin-right: -15px;
        border-radius: 0;
        height: 100vh !important;
        min-height: 100vh !important;
        position: fixed !important;
        right: 15px;
        top: 0;
        width: 50vw !important;
    }
    .station-index{
        .endurvinnslustodvar-container{
            h2{
                margin-bottom: 22px;
            }

            ul{
                li{
                    .inner-container{
                        line-height: 16px;
                        margin-bottom: 20px;
                        min-height: 47px;

                        &.active{
                            border: 1px solid $primary-green !important;
                        }

                        &:hover{
                            border-color: $primary-green !important;
                        }

                        span{
                            line-height: 20px !important;
                            padding-right: 10px;
                        }

                        .opening-hrs-span{
                            min-width: 125px;
                            width: 125px;
                            font-size: 14px !important;

                            @include media-breakpoint-down(md){
                                min-width: 100px;
                                width: 100px;
                            }
                        }
                    }

                    .more{
                        margin-bottom: 20px;
                        min-width: 140px;

                        @media (max-width: 1530px){
                            min-width: 70px;
                        }

                        &:hover{
                            border-color: $primary-green !important;
                            transition: border-color .2s linear;
                        }

                        span.hide-text{
                            @media (max-width: 1530px){
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        ul{
            li.parent{
                &+li.parent{
                    border-top: none;
                }
                
                background-color: transparent;
                max-height: 55px;
                height: auto;
                transition: max-height .3s ease-out;
                cursor: pointer;
                vertical-align: middle;
                overflow: hidden;

                &::after{
                    content: none;
                }

                span.open-postal{
                    position: relative;
                    z-index: 2;
                }

                .arrow-container{
                    right: 20px;
                    top: 15px;
                    z-index: 1;

                    .arrow{
                        width: 1.6rem;
                        height: 1.2rem;
                        display: inline-block;
                        position: relative;
                        margin: 0 1rem;

                        span{
                            top: .5rem;
                            position: absolute;
                            width: 1rem;
                            height: .15rem;
                            background-color: $font-light;
                            display: inline-block;
                            transition: all .2s ease;

                            &:first-of-type{
                                left: 0;
                                transform: rotate(45deg);
                            }

                            &:last-of-type{
                                right: 0;
                                transform: rotate(-45deg);
                            }
                        }
                    }

                    &.active{
                        .arrow{
                            span{
                                background-color: $primary-blue;

                                &:first-of-type{
                                    transform: rotate(-45deg);
                                }

                                &:last-of-type{
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }

                &.open{
                    border-top: 1px solid $primary-blue;
                    max-height: 10000px;

                    >span{
                        border-bottom: 1px solid $primary-blue;
                    }

                    &::after{
                        border-color: $primary-blue;
                        transform: rotate(225deg);
                        transition: transform .5s linear;
                    }

                    ul.inner-ul{
                        padding-bottom: 20px;
                        
                        >li.middle{
                            min-height: 45px;
                            
                            >span{
                                max-height: 45px;
                                line-height: 45px;
                                margin-bottom: 22px;
                            }

                            .arrow-container{
                                top: 9px;
                            }

                            &:first-child{
                                border-top: none;
                            }

                            &:last-child{
                                border-bottom: none;
                            }

                            &.open{
                                border-bottom: none;

                                >span{
                                    border-bottom: 2px solid $primary-blue;
                                    transition: border-bottom .2s linear;
                                }
                            }
                        }

                        ul.icons-list{
                            flex-wrap: wrap;
                            max-width: 58%;

                            @include media-breakpoint-down(md){
                                max-width: 100%;

                                li.icons{
                                    img{
                                        width: 56px !important;
                                    }
                                }
                            }

                            li.icons{
                                img{
                                    width: 42px;
                                }
                            }
                        }

                        .show-on-map{
                            font-size: 1.3rem;
                            font-weight: 500;
                            display: inline;
                            height: auto;
                            line-height: 1.5;
                            border-bottom: 1px solid $primary-blue;
                        }

                        span{
                            white-space: nowrap;
                        }
                    }
                }

                span{
                    height: 55px;
                    line-height: 55px;
                }

                ul.inner-ul{
                    margin-bottom: 30px;
                    margin-top: 27px;
                    overflow: hidden;

                    li.middle{
                        background: transparent;
                        max-height: 45px;
                        overflow: hidden;
                        &+li.middle{
                            border-top: none;
                        }

                        >span{
                            max-height: 45px;
                            line-height: 45px;
                        }

                        &.open{
                            max-height: 10000px;
                            overflow: visible;

                            &::before{
                                background-color: $primary-blue;
                                content: '';
                                height: 2px;
                                left: -22px;
                                position: absolute;
                                top: 26px;
                                width: 15px;
                            }
                        }

                        ul:not(.icons-list){
                            li{
                                border-top-width: 1px;
                                min-height: 56px;
                                border-color: #e6e6e6;

                                &::after{
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .list-group-item.icons{
            display: flex;
            align-items: center;
        }

        .list-group-item + .list-group-item {
            border-top-width: 1px !important;
        }
    }
}

@include media-breakpoint-down(lg){
    .page-stationindexpage{    
        .left{
            height: fit-content;
        }

        .right-column{
            display: none;
        }

        .mapboxgl-map{
            position: relative !important;
            right: 0;
            max-height: 65vh;
            height: 65vh !important;
            width: 100% !important;
        }
    }
}

@include media-breakpoint-down(lg){
    .page-stationindexpage, .page-stationpage{
        .more{
            position: absolute;
            left: 0;
            height: 47px;
            width: 100%;
            opacity: 0;
        }

        .left{
            background-color: $white;
        }

        .sorpa-map-container{
            height: calc(100vh - 70px);
            position: absolute;
            width: 100vw;
            z-index: -10;

            .close-map{
                background-color: transparent;
                color: $font-lighter !important;
                margin-right: 20px;

                svg{
                    margin-left: 11px;
                    width: 16px;
                }
            }

            &.show-map{
                background-color: $white;
                display: block;
                height: calc(100vh - 70px);
                left: 0;
                margin-left: 0;
                margin-top: 0;
                padding-top: 40px;
                position: fixed;
                top: 70px;
                z-index: 9;
            }

            .mapboxgl-map{
                min-height: calc(100vh - 70px) !important;
            }
        }

        .station-index{
            .endurvinnslustodvar-container{
                h2{
                    margin-bottom: 0;
                }

                .see-map{
                    margin-bottom: 20px;
                }

                ul{
                    li{
                        .inner-container{
                            .opening-hrs-span{
                                margin-right: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}