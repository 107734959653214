.page-publishedcontentpage{
    section{
        padding-top: 100px;
    }

    .title-block{
        margin-bottom: -28px;

        .title-container{
            &::after{
                bottom: -40px !important;
                height: calc(100% + 100px);
                left: -30px !important;
            }
        }
    }
    .filter-container{
        background-color: $light-blue-bg;
        margin-bottom: 100px;
        padding: 100px 30px 30px;

        .col{
            &.input-col{
                &::after{
                    content: none;
                }

                .clear-filter{
                    top: -34px;

                    .clear-icon-container{
                        height: 10px;
                        width: 10px;

                        svg{
                            min-width: 18px;
                        }
                    }
                }
            }

            .inner-container{
                background-color: $white;

                &::after{
                    content: '';
                    background-color: $primary-blue;
                    height: 28px;
                    position: absolute;
                    right: 34px;
                    top: 8px;
                    width: 1px;
                }

                svg.arrow-head{
                    position: absolute;
                    right: 11px;
                    top: 19px;
                    width: 13px;
                    z-index: 1;         
                }
            }
        }

        input, select{
            border-radius: 3px;
            height: 44px;
            padding: 10px 16px;
            
            &:focus, &:active{
                outline: none;
            }
            
            &::placeholder{
                color: $gray-l2;
                font-style: italic;
            }
        }

        select{
            appearance: none;
            background-color: transparent;
            position: relative;
            z-index: 8;
        }
    }

    .document{
        border: 1px solid $gray-l1;
        border-radius: 3px;
        min-height: 64px;
        line-height: 32px;
        padding: 18px 27px 20px 20px;

        &:hover{
            border: 1px solid $primary-blue;

            svg{
                g g{
                    fill: $primary-blue;
                }
            }
        }

        .doc-type{
            font-size: 1.3rem;
        }
    }
}

@include media-breakpoint-down(md){
    .page-publishedcontentpage{
        .filter-container{
            margin-bottom: 50px;

            .col{
                margin-bottom: 14px;
                min-width: 270px;
                width: 100%;

                .clear-filter{
                    top: -150px !important;
                }
            }
        }

        .document{
            font-size: 2rem;
            line-height: 25px !important;
            padding: 12px 20px 12px 20px;

            .file-icon{
                width: 19px;
            }

            .date{
                line-height: 1.8rem !important;
            }
        }
    }
}