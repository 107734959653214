.font-black{
    color: $black;
}

.font-light{
    color: $font-light;
}

.font-lighter{
    color: $font-lighter;
}

.font-gray{
    color: $gray;
}

.font-smaller{
    font-size: 1.4rem;
}

.font-small{
    font-size: 1.6rem;
}

.font-medium{
    font-size: 1.8rem;
    line-height: 3rem;
}

.medium-weight{
	font-weight: 500;
}

.normal-weight{
	font-weight: 400;
}

.demi-weight{
	font-weight: 600;
}

.font-D-DIN{
    font-family: 'D-DIN condensed', sans-serif;
}

.italic{
    font-style: italic;
}

@font-face {
    font-family: 'D-DIN condensed';
    src: url('../fonts/D-DINCondensed-Bold.woff2') format('woff2'),
        url('../fonts/D-DINCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}