.page-categoryindexpage, .page-categorypage, .page-subcategorypage, .page-stationpage{
    .category-page-ul{
        padding-top: 40px;

        ul{
            @include categories-li;
        }

        &.list{
            ul{
                &.parent-ul{
                    max-height: 72vh;
                }

                li.parent{
                    height: auto !important;
                    min-height: 54px;

                    &.mt-neg{
                        margin-top: -2px;
                    }
                    
                    &::after{
                        content: none;
                    }
                    
                    a{
                        line-height: 52px; 

                        &.open{
                            border-color: $primary-blue !important;
                            color: $primary-blue;
                        }

                        .arrow-container{
                            right: 0;
                            top: 0;
                            z-index: 1;
        
                            .arrow{
                                width: 1.6rem;
                                height: 1.2rem;
                                display: inline-block;
                                position: relative;
                                margin: 0 1rem;
        
                                span{
                                    top: .5rem;
                                    position: absolute;
                                    width: 1rem;
                                    height: .15rem;
                                    background-color: $font-light;
                                    display: inline-block;
                                    transition: all .2s ease;
        
                                    &:first-of-type{
                                        left: 0;
                                        transform: rotate(45deg);
                                    }
        
                                    &:last-of-type{
                                        right: 0;
                                        transform: rotate(-45deg);
                                    }
                                }
                            }
        
                            &.active{
                                .arrow{
                                    span{
                                        background-color: $primary-blue;
        
                                        &:first-of-type{
                                            transform: rotate(-45deg);
                                        }
        
                                        &:last-of-type{
                                            transform: rotate(45deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    ul.sub-ul{
                        display: none;
                        padding-left: 37px;
                        overflow: hidden;

                        &.open{
                            display: block;
                        }
                        
                        li{
                            margin-top: 0;
                            height: 45px !important;
                            transition: padding-left .3s linear;

                            &::before{
                                background-color: $primary-blue;
                                content: '';
                                height: 1px;
                                left: -25px;
                                position: absolute;
                                top: 22px;
                                transition: width .3s linear;
                                width: 12px;
                            }

                            &::after{
                                content: none;
                            }

                            &:hover{
                                cursor: pointer;
                                padding-left: 8px !important;
                                transition: padding-left .3s linear;

                                &::before{
                                    transition: width .3s linear;
                                    width: 20px;
                                }
                            }

                            a{
                                line-height: 36px !important;
                            }

                            &.active-state{
                                padding-left: 8px !important;

                                &::before{
                                    width: 20px;
                                }

                                a{
                                    color: $primary-blue;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-categoryindexpage, .page-subcategorypage, .page-stationpage{
    .back{
        padding-left: 20px;

        &::before{
            content: '';
            left: 2px;
            top: 36%;
            position: absolute;
            display: block;
            width: 0px;
            height: 0px;
            border: solid $font-light;
            border-width: 0 1.5px 1.5px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(-226deg);
            transition: left .2s ease-in-out;
        }

        &:hover{
            color: $black;

            &::before{
                left: -2px;
                transition: left .2s ease-in-out;
            }

            a{
                color: $black;
            }
        }
    }
}

.page-categoryindexpage, .page-subcategorypage{
    &:not(.menu-open){
        .left-column{
            height: 100%;
            position: sticky;
            top: 0;
        }    
    }

    .right-column{
        padding: 0;
        
        .bordered{
            margin-right: 0 !important;
        }
    }
}

.page-categoryindexpage, .page-subcategorypage{
    .right-column{
        .cat-icon{
            max-height: 176px;
            max-width: 176px;
            width: 100%;
        }

        .info{
            p{
                color: $font-lighter;
                font-size: 1.8rem;
                line-height: 2.8rem;
            }
        }

        .closest-station{
            span.heading{
                font-size: 2rem;
            }

            a{
                color: $primary-blue;
                transition: color .2s linear;
                &:hover{
                    color: $font;
                    transition: color .2s linear;
                }
            }

            .station{
                border-radius: 6px;
                padding: 7px 12px;
                margin-bottom: 20px;
                
                span{
                    font-weight: 600 !important;
                }
            }

            .opening-hrs-container{
                border: 1px solid $border-color-2;

                &:hover{
                    cursor: pointer !important;
                    transition: border-color .3s linear;
                    
                    &.grenndarstod{
                        border-color: $primary-blue !important;
                    }

                    &.endurvinnslustod{
                        border-color: $primary-green;
                    }
                }
            }
        }

        .how-its-recycled{
            .title{
                border-bottom: 1px solid $gray;
                font-size: 2.4rem;
                line-height: 3.1rem;
                margin-bottom: 18px;
                padding-bottom: 4px;
            }
        }
    }

    footer{
        margin-top: 0 !important;   
    }
}



.page-categorypage{
    .right-column{
        padding-right: 0 !important;
    }

    .category-page-ul{
        padding-top: 40px;

        ul{
            li{
                &:hover{
                    .colorbar{
                        transition: none;
                        width: 36px;
                    }
                }
            }
        }
    }
}

.page-subcategorypage{
    .right-column{
        .pricelist-inner-container{
            .pricelist--item:not(.header){
                .pricelist--item--price{
                    align-items: flex-end;
                    display: flex;
                    justify-content: flex-end;
                }
                
                .pricelist--item--description{
                    position: relative;
                    white-space: pre-wrap !important;
                    
                    &::after{
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .page-subcategorypage{
        .left-column{
            display: none;
        }

        .right-column{
            padding: 0 !important;

            .bordered{
                border: none;
                padding-top: 40px;

                .pricelist-inner-container{
                    overflow: scroll;
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                    
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    
                    .pricelist--body{
                        min-width: 400px;

                        .pricelist--item--price{
                            margin-left: 0;
                        }
                    }
                }
            }

            .mb-50{
                margin-bottom: 24px !important;
            }

            .info{
                margin-bottom: 50px;
            }

            .primary-btn{
                width: fit-content;
            }
        }
    }

    .page-categorypage{
        .right-column{
            display: none;
        }
    }

    .page-categoryindexpage{
        .right-column{
            display: none;
        }
    }
}