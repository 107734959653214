.header-info-block{
    margin-bottom: 40px;
    padding: 20px 27px;

    svg{
        min-width: 31px;
    }

    &.info{
        background-color: $info-bg;

    }
    
    &.warning{
        background-color: $warning-bg;
    }

    a{
        border-bottom: 1px solid $primary-green;
        max-height: 30px;
    }
}

@include media-breakpoint-down(md){
    .header-info-block{
        margin-bottom: 20px;
        padding: 16px 14px 16px 45px;

        svg{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
        }
    }
}