.social-container{
    padding-left: 15px;
    transform: translateX(-50%);
    z-index: -1;

    .social-share{
        margin-top: -20px;
        top: 20px;
    }
}

@include media-breakpoint-down(md){
    .social-container{
        bottom: -80px;
        height: fit-content !important;
        padding-left: 0;
    }
}