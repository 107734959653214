.latest-news-block{
    padding-bottom: 100px;
    padding-top: 111px;
}

.news-block {
    margin-bottom: 40px;

    &:hover{
        .title{
            border-bottom: 1px solid $primary-blue;
        }
    }

    .img-container{
        background-color: #fff;
        border: none;
        max-height: 270px;
        min-height: 270px;
        width: 100%;

        img{
            object-fit: cover;
            height: auto;
            min-height: 100%;
            min-width: 100%;
            width: auto;
        }
    }
    .text-container{
        background-color: $white;
        margin-top: -50px;
        max-width: 405px;
        width: 90%;
        padding: 13px 0 13px 20px;

        .inner{
            line-height: 0;
            padding-bottom: 50px;
            padding-right: 20px;

            &:after{
                background-color: $light-grey-bg;
                content: '';
                left: -36px;
                height: calc(100% + 32px);
                position: absolute;
                top: 0;
                width: calc(100% + 36px);
                z-index: -1;
            }

            .title{
                color: $black;
                max-height: 89px;

                p{
                    line-height: 28px;
                }
            }
        }
        .readmore{
            font-size: 1.8rem;
        }
    }
}


@include media-breakpoint-down(md){
    .latest-news-block{
        padding-bottom: 50px;
        padding-top: 50px;
    }
}