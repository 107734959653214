.pagination{
    li{
        background-color: $gray-l3;
        height: 32px;
        margin: 0 4px;
        width: 32px;

        &.wider{
            background-color: transparent;
            width: auto;
        }

        &:hover{
            &:not(.wider){
                border: 1px solid $primary-blue !important;
                
                span{
                    background-color: transparent !important;
                }
            }

            &.wider{
                a{
                    color: $primary-blue;
                }
            }
        }

        &.active{
            background-color: $primary-blue;

            a{
                color: $white !important;
            }
        }

        span, a{
            border: none;
            background-color: transparent !important;
            color: $font-lighter;
        }

        abbr{
            text-decoration: none;
        }
    }
}

@include media-breakpoint-down(md){
    .pagination{
        flex-wrap: wrap;
        justify-content: center;

        li{
            margin-bottom: 12px;
        }
    }
    
}