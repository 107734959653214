html {
    @include media-breakpoint-only(xs) { 
        font-size: clamp(9px, 2.08333vw, 10px);
    }
    @include media-breakpoint-only(sm) { 
        font-size: clamp(9px, 1.52439vw, 10px);
    }
    @include media-breakpoint-only(md) { 
        font-size: clamp(9px, 0.99vw, 10px);
    }
    @include media-breakpoint-up(lg) { 
        font-size: clamp(9px, 0.71685vw, 10px);
    }
    
    &.translated-ltr{
        header{
            top: 24px !important;

            &.show-menu{
                margin-top: -16px;
            }
        }
    }
}
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
    color: $font;
    font-family: 'futura-pt';
    min-height: 100vh;
    font-size: 2rem;
    overflow-x: hidden;
    width: 100%;

    &.menu-open{
        @include media-breakpoint-down(lg){
            position: fixed;
        }

        .header-container{
            @include media-breakpoint-up(lg){
                flex: 0 0 auto;
                width: 50%;
            }
        }

        .map-row{
            .mapboxgl-map{
                z-index: -2;
            }
        }
    }

    &.page-categorypage, &.page-stationindexpage, &.page-stationpage{
        header{
            .google-translate-container{
                right: 54%;
            }
        }
    }

    &.menu-open{
        header{
            .google-translate-container{
                right: 57%;
            }
        }
    }

    &:not(.menu-open):not(.split-screen):not(.page-stationindexpage){
        padding-top: 104px;
    }

    @include media-breakpoint-down(lg){
        &.search-open{
            position: fixed !important;
        }
    }

    section{
        background-color: $white;
        min-height: 50vh;
    }
}

h1, h2, h3 {
    color: $black;
}

h1{
    font-size: 5.2rem;
    line-height: 5.4rem;
    
    @include media-breakpoint-down(md){
        font-size: 3.2rem;
        line-height: 3.6rem;
    }
}

h2{
    font-size: 3.2rem;
    line-height: 4rem;

    @include media-breakpoint-down(md){
        font-size: 2.8rem;
        line-height: 3.1rem;
    }
}

h3{
    font-size: 2.8rem;
    line-height: 3.6rem;
    font-weight: 600;
}

h4{
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-weight: 600;
}

h5{
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 600;
}

p, span{
    font-size: 2.2rem;
    line-height: 3.2rem;
}
p{
    margin-bottom: 2.2rem;
}

a, .blue{
    color: $primary-blue;
    text-decoration: none;

    &:hover{
        color: $blue-hover;
    }
}

.btn-disabled{
    color: $btn-disabled;
}

.contact-bubble{
    height: 0;
    z-index: 10;
    
    .rounded-pill{
        background: $footer-bg;
        bottom: 40px;
        left: -194px;
        height: 60px;
        width: 204px;
    }

    .content-closed{
        svg{
            margin-right: 10px;
            width: 26px;
        }

        span{
            font-size: 2rem;
        }
    }

    .bubble-content{
        background-color: $white;
        bottom: -10px;
        border-radius: 16px;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.15);
        cursor: auto !important;
        display: none;
        min-width: 302px;
        padding: 70px 28px 28px;
        right: -10px;

        .close-bubble{
            height: 20px;
            right: 15px;
            top: 20px;
            width: 20px;

            span{
                background-color: $font-lighter;
                height: 2px;
                width: 100%;

                &:first-child{
                    transform: rotate(45deg) translate(1px, 1px);
                }

                &:last-child{
                    transform: rotate(-45deg);
                }
            }
        }

        ul{
            padding-left: 36px;

            li{
                position: relative;

                &::before{
                    content: '';
                    background-color: $primary-blue;
                    height: 2px;
                    left: -8px;
                    position: absolute;
                    top: 20px;
                    width: 11px;
                }
            }
        }
    }
}

.width-100-mobile{
    @include media-breakpoint-down(md){
        min-width: 100vw;
    }
}

.mobile-w-100{
    @include media-breakpoint-down(xl){
        min-width: 100%;
    }
}

.right-column{
    min-height: 97vh;
}

.bg-none{
    background-color: transparent;
}

.bg-cover{
    background-size: cover;
}

button{
    &:active, &:focus{
        outline: none !important;
        box-shadow: none !important;
    }
}

.scale-fix{
    @media (min-width: 1200px) and (max-width: 1300px){
        margin-left: 0px !important;
    }
}

.overflow-scroll{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.dot{
    height: 10px;
    min-width: 10px;
    width: 10px;
    &.closed{
        background-color: $ahaettuurgangur!important;
    }

    &.open{
        background-color: $primary-green;
    }
}

.mb-n100{
    margin-bottom: -100px;
}

.md-break{
    @include media-breakpoint-down(md){
        flex-basis: 100%;
        height: 0;
    }
}

.flokkum-lang-nav{
    position: absolute;
    right: 100px;
    @include media-breakpoint-down(lg){
        right: 40px;
    }
    a{
        color: $font;
    }
}
#wagtail-userbar-trigger{
    font-size: 1.6rem!important;
}