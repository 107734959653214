.staff-block{
    table{
        background-color: transparent;

        td{
            .heading{
                height: 70px;

                .plus-icon{
                    transform: rotate(0) translateY(-50%);
                    transition: transform .2s linear;

                    span{
                        background-color: $black;
                        height: 1.5px;
                        width: 16px;

                        &:first-child{
                            transform: rotate(90deg) translate(2px, 0);
                        }
                    }
                }

                &.show-table{
                    border-bottom: 2px solid $primary-blue !important;

                    .plus-icon{
                        transform: rotate(45deg);
                        transition: transform .2s linear;

                        span{
                            background-color: $primary-blue;
                        }
                    }
                }
            }

            .inner-table{
                max-width: 93vw;

                table{
                    // min-width: 750px;

                    td{
                        line-height: 21px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg){
    .staff-block{
        table{
            td{
                .heading{
                    min-height: 70px;
                    line-height: 18px;
                    padding-right: 50px;

                    .plus-icon{
                        right: 20px !important;
                    }
                }

                .inner-table{
                    max-width: 85vw;
                }
            }
        }
    }
}

// @include media-breakpoint-down(sm){
//     .staff-block{
//         table{
//             td{
//                 .inner-table{
//                     max-width: 52%;
//                 }
//             }
//         }
//     }
// }