.pricelist {
    @include pricelist
}

.page-categoryindexpage, .page-subcategorypage {
    .pricelist {
        border: 1px solid $primary-blue;
        border-radius: 4px;
        background-color: #F8FAFA;
        padding: 18px 22px;
        overflow: hidden;

        @include pricelist;

        &--item {
            padding: 0;
            border-bottom: none;

            &.header {
                margin-top: 15px;
                .pricelist--item--price {
                    font-size: 1.6rem;
                    font-weight: 600;
                    margin-left: 15px;
                    width: 90px;

                    span {
                        font-size: 1.6rem;
                        font-weight: 400;
                    }
                }

                .pricelist--item--description {
                    padding-top: 0;
                    font-size: 1.8rem;
                    font-weight: 600;

                }
            }

            &--description {
                font-size: 1.8rem;
                font-weight: normal;

                &:after {
                    content: '.............................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................................';
                }

                &.no-dots {

                    &:after {
                        content: '';
                    }

                }
            }

            &--price {
                font-size: 1.8rem;
                margin-left: 15px;
                width: 90px;

                span {
                    font-size: 1.8rem;
                    line-height: unset;
                }
            }
        }





    }
}

[data-pricelist-container] {
    h2 {
        margin-bottom: 15px;
        &:hover {
            a.headerlink {
                visibility: visible;
                border: none;
            }
        }

        a.headerlink {
            visibility: hidden;
        }
    }
}

@include media-breakpoint-down(md){
    .pricelist-table-container, .pricelist-inner-container{
        margin-left: -40px;
        padding-left: 40px;
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }

        .pricelist{
            &--item{
                // min-width: 600px;

                &--code{
                    min-width: 96px;
                }

                &.header{
                    margin-top: 20px;
                    
                    &:first-child{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}