$grid-columns: 16;
$grid-gutter-width: 1.5625vw;

$border-radius: .8rem;

.clamped{
    max-width: 1920px;

    @media (min-width: 1920px){
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.inner-container-smaller{
    max-width: 1402px;
}

.mb-6{
    margin-bottom: 26px !important;
}

.mb-40{
    margin-bottom: 4rem !important;
}

.mb-50{
    margin-bottom: 5rem !important;
}

.mb-60{
    margin-bottom: 6rem !important;
}

.mb-70{
    margin-bottom: 7rem !important;
}

.pe-70{
    padding-right: 7rem !important;
}

.px-20{
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

.me-50{
    margin-right: 5rem !important;
}

.border-blue{
    border: 1px solid $primary-blue;
}

.border-grey{
    border: 1px solid $gray-l1;
}

.cursor-pointer{
    cursor: pointer;
}

.primary-btn{
    border-radius: 4px;
    font-size: 1.4rem !important;
    line-height: 1.8rem !important;
    padding: 6px 17px;
}

.bordered{
    border-width: 14px;
    border-style: solid;
    min-height: 100vh;
    padding-top: 80px;
    >.container{
        padding-bottom: 150px;
    }

    img{
        height: auto;
        width: 100%;
    }
}

.btn.see-more{
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 23px;    
    margin-top: 65px;
    width: fit-content;
    padding: 10px 40px;
    
    &:hover{
        border-color: $primary-blue !important;
        transition: border-color .3s linear;
    }

    a{
        color: $black;

    }
}