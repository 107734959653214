.info-block{
    background-color: $light-grey-bg;
    margin: 85px 0;
    padding-bottom: 156px;
    padding-top: 156px;

    p{
        a{
            color: $font-light;
            font-weight: 600;
            border-bottom: 1px solid $primary-blue;
        }
    }
    // a.read-more{
    //     background-color: $white;
    //     border-color: $border-color;
    //     height: 46px;
    //     margin-top: 60px;
    //     width: 146px;
    // }

    .subtitle{
        color: $primary-green;
    }

    .image-container{
        .inner-img-container{
            max-width: 709px;

            &::after{
                background-color: $white;
                bottom: -40px;
                content: '';
                left: -35px;
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 0;
            }

            img{
                z-index: 1;
            }
            
            .recycle-img{
                bottom: -95px;
                right: -75px;
                z-index: 1;
                width: 50%;
            }
        }
    }
}

@include media-breakpoint-down(xl){
    .info-block{
        padding-bottom: 80px;
        padding-top: 80px;

        >.row{
            padding-right: 20px;
            padding-left: 20px;
        }

        .inner-img-container{
            margin-bottom: 80px;

            &::after{
                bottom: unset;
                top: -40px;
            }

            .recycle-img{
                right: 10px !important;
            }
        }
    }   
}

@include media-breakpoint-down(md){
    .info-block{
        padding: 70px 0 !important;

        .image-container{
            .inner-img-container{
                &::after{
                    content: none;
                }
            }
        }
    }   
}