.cta-button-block{
    height: 50px;

    a{
        background-color: $primary-blue;
        border-radius: 23px;
        color: $white;
        line-height: 32px;
        padding: 13px 23px;
        transition: background-color .1s linear;

        &:hover{
            background-color: $primary-green;
            transition: background-color .1s linear;
        }
    }
}

.mb-60 + .cta-button-block{
    margin-top: -40px;
}