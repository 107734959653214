.table-block{
    >.col-xl-8{
        overflow: hidden;
    }
}

.table-container{
    background-color: $table-bg;
    border-radius: 16px;
    padding: 41px 32px 30px 32px;

    table{
        margin-bottom: 0;
        min-width: 600px;
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    
        &::-webkit-scrollbar {
            display: none;
        }

        tr{
            height: 50px;
        }

        thead{
            th{
                border-bottom: 1px solid $primary-blue !important;
                font-weight: 600!important;
            }
        }

        tbody{
            border-top: none !important;

            tr{
                td{ 
                    border-bottom: 1px solid $table-border;
                    color: $font-lighter;
                    vertical-align: middle;
                }

                &:last-child{
                    td{
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .table-container{
        border-radius: 0;
        padding: 41px 0 30px 32px;
    }
}